<template>
  <div class="appAccount page">
    <div class="flex">
      <div class="account cur" @click="titChange(1)">平台台账</div>
      <div class="account cur account-active" @click="titChange(2)">APP台账</div>
      <div class="account cur" @click="titChange(3)">已注销台账</div>
    </div>
    <!-- 筛选 -->
    <div class="select-box m-b-32">
      <div>
        <span class="m-r-16">APP名称</span>
        <el-input v-model="name" placeholder="请输入APP名称" size="medium" clearable></el-input>
      </div>
      <div class="select-box-group-btn flex-c-c">
        <div class="queryBtn" @click="toSearch">查询</div>
        <div class="resetBtn" @click="resetFn">重置</div>
      </div>
    </div>
    <!-- 列表 -->
    <div class="data-list">
      <!-- 加载中 -->
      <el-skeleton :rows="26" :loading="ifOpen" animated />
      <div class="data-box" v-for="(item, index) in queryList" :key="index">
        <div class="data-box-left">
          <!-- <el-image :src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg">
            <div slot="error" class="image-slot"> -->
          <img src="@/assets/img/zhanwei2.png" alt="" />
          <!-- </div>
          </el-image> -->
          <div class="platform-type">{{ 'APP' }}</div>
        </div>
        <div class="data-box-right">
          <div class="name-box">
            <template>
              <!-- <img v-if="item.gatherLevel==1" src="../../assets/img/zhang/zhongdian.png" class="m-r-6" alt=""> -->
              <div class="name" @click="toDetail(item)" v-html="item.applyName ? item.applyName : '测试'" />
            </template>
          </div>
          <!-- <div class="data-info">
          </div> -->
          <div class="operation">
            <div class="operation-left">
              <div>平台状态：<span style='color:#02bc7c'>正常</span></div>
              <!-- <template v-if="item.messageTypeNameList.length > 0">
              <span class="colRed">{{ item.messageTypeNameList[0] + "   " }}
              </span>
              <el-tooltip class="item" effect="dark" placement="top">
                <div slot="content" v-html="item.messageTypeNameDesc"></div>
                <span>(更多)</span>
              </el-tooltip>
            </template>
            <div class="status" v-else>正常</div> -->
              <div class="region m-l-16">
                所属区域：{{ '重庆市-渝北区' }}
              </div>
            </div>
            <div class="operation-right">
              <div class="detail" @click="toDetail(item)">
                <img src="@/assets/img/wang/detail.png" alt="" />
                <div>查看详情</div>
              </div>
              <div class="remove" @click="deleteFnMy(item)">
                <img src="@/assets/img/wang/rev.png" alt="" />
                <div>删除</div>
              </div>
            </div>
          </div>
        </div>
        <!-- </div> -->
        <img v-if="item.isCancelled" class="disposed" src="@/assets/img/wang/yizhuxiao.png" alt="" />
      </div>
      <!-- 位图 -->
      <Bitmap :desc="'列表'" v-if="queryList.length == 0&&!ifOpen" />
      <Pagination :pageNumber="page.pageNo" :pageSize="page.pageSize" :total="total" @SizeChange="SizeChange" @CurrentChange="CurrentChange">
      </Pagination>
    </div>
    <!-- 删除弹出框 -->
    <DeleteDialog ref="openDialog" @deleteFn="deleteaccount"></DeleteDialog>
  </div>
</template>

<script>
import Pagination from "@/components/pagination.vue"; // 分页
import Bitmap from "@/components/bitmap.vue"; // 位图
import DeleteDialog from "@/components/deleteDialog.vue";
import {
  getList,
  appUpdate,
  appDel,
} from "@/api/appAccount/index";
export default {
  components: {
    Pagination,          // 分页
    Bitmap,             // 位图
    DeleteDialog,      // 删除弹出框子
  },
  data() {
    return {
      name: '',
      queryList: [],
      ifOpen: false,
      page: {
        pageNo: 1,
        pageSize: 10
      },
      total: 0,
      deleteID: ''//删除时要用到的id
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    // 头部切换
    titChange(val) {
      if (val == 1) {
        this.$main.routeResolve('/platformaccount')
      } else if (val == 2) {
        this.$main.routeResolve('/appAccount')
      } else if (val == 3) {
        this.$main.routeResolve('/cancelAccount')
      }
    },
    // 请求数据
    async getList() {
      let data = {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        applyName: this.name
      }
      this.ifOpen = true
      let res = await getList(data)
      if (res.data.code == 200) {
        this.queryList = res.data.data.list
        this.total = res.data.data.totalCount * 1
        this.ifOpen = false
      }
    },
    // 搜索
    toSearch() {
      this.page.pageNo = 1
      this.getList()
    },
    // 重置
    resetFn() {
      this.name = ''
      this.toSearch()
    },
    // 跳转详情
    toDetail(item) {
      this.$main.routeResolve('/appDetails', item)
    },
    // 删除按钮
    deleteFnMy(item) {
      console.log(item);
      this.$confirm('此操作将永久删除该App, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let res = await appDel(item.id)
        if (res.data.code == 200) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.getList()
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //删除弹框
    deleteaccount() { },
    SizeChange(val) {
      this.page.pageSize = val
      this.page.pageNo = 1
      this.getList()
    },
    CurrentChange(val) {
      this.page.pageNo = val
      this.getList()
    },
  }
}
</script>

<style lang="scss" scoped>
.appAccount {
  .account {
    min-width: 120px;
    width: auto;
    height: 37px;
    font-size: 26px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #1a1a1a;
    line-height: 37px;
    margin-bottom: 24px;
  }
  .account-active {
    color: #f58030;
  }
  .select-box {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    height: auto;
    width: 100%;
    background: #ffffff;
    border-radius: 4px;
    .el-input {
      width: 190px;
    }
  }
  .data-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    background: #ffffff;
    border-radius: 4px;
    padding: 24px;
    .data-box {
      display: flex;
      width: 100%;
      height: 157px;
      padding: 24px 0px;
      border-bottom: 1px solid #e4e6ec;
      .disposed {
        height: 114px;
        width: 129px;
        position: absolute;
        right: 138px;
      }
      .data-box-left {
        width: 154px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          height: 56px;
          width: 56px;
          // border-radius: 50%;
        }
        .platform-type {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 24px;
          width: auto;
          background: #f58030;
          // max-width: 104px;
          padding: 0px 22px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          margin-top: 9px;
        }
      }
      .data-box-right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: calc(100% - 104px);
        height: 100%;
        .name-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 25px;
          margin-bottom: 16px;
          .name {
            height: 25px;
            width: 80%;
            line-height: 25px;
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: bold;
            color: #333333;
            cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
          }
          .bad-num {
            display: flex;
            justify-content: flex-end;
            font-size: 14px;
            width: 20%;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #e60b1e;
          }
        }
        .data-info {
          display: flex;
          align-items: center;
          height: 20px;
          width: 100%;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          margin-bottom: 16px;
          .status {
            color: #02bc7c;
            // margin-right: 46px;
          }
          .region {
            max-width: 180px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-right: 46px;
          }
          .subject-type {
            margin-right: 46px;
          }
          .company {
            max-width: 300px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-right: 46px;
          }
          .fnas {
            margin-right: 46px;
          }
        }
        .operation {
          display: flex;
          height: 32px;
          width: 100%;
          .operation-left {
            display: flex;
            align-items: center;
            width: 60%;
            height: 100%;
            span {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              margin-right: 8px;
            }
            .select {
              //下拉选择
              width: 104px;
              height: 32px;
              ::v-deep .el-input__inner {
                width: 104px;
                height: 32px;
                border-radius: 4px;
              }
              ::v-deep .el-select__caret.el-input__icon.el-icon-arrow-up {
                display: flex;
                align-items: center;
                justify-content: center;
              }
              ::v-deep .el-input__icon.el-icon-arrow-down {
                display: flex;
                align-items: center;
                justify-content: center;
              }
              ::v-deep .el-input__icon.el-icon-circle-close {
                display: flex;
                align-items: center;
                justify-content: center;
              }
              ::v-deep .el-cascader {
                height: 32px !important;
                margin-top: 0px;
                .el-input.el-input--suffix {
                  height: 32px;
                  display: flex;
                  align-items: center;
                }
              }
            }
          }
          .operation-right {
            // position: absolute;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 40%;
            height: 100%;
            .detail {
              display: flex;
              height: 100%;
              width: auto;
              align-items: center;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              cursor: pointer;
              margin-right: 24px;
              img {
                height: 14px;
                width: 13px;
                margin-right: 7px;
              }
            }
          }
          .programme {
            display: flex;
            height: 100%;
            width: auto;
            align-items: center;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #02bc7c;
            cursor: pointer;
            margin-right: 24px;
            img {
              height: 14px;
              width: 15px;
              margin-right: 7px;
            }
          }
          .remove {
            display: flex;
            height: 100%;
            width: auto;
            align-items: center;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #e60b1e;
            cursor: pointer;
            margin-right: 24px;
            img {
              height: 15px;
              width: 15px;
              margin-right: 7px;
            }
          }
        }
      }
    }
  }
}
</style>