<template>
  <!-- 列表暂无数据 -->
  <div class="analyse-container" :style="{'marginTop':peizhi['m-t'],'padding-top':peizhi['p-t']}">
    <div class="analyse-container-img">
      <img src="@/assets/img/gjh/weitu.png" />
      <p>{{ desc }}{{defaultValue}}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    desc: {
      type: String,
      default: "",
    },
    defaultValue:{
      type: String,
      default: "暂无数据",
    },
    peizhi:{
      type:Object,
      default:() => {
        return {
          'm-t':'60px',
          'p-t':'60px'
        }
      }
    }
  },
};
</script>

<style scoped>
/* 暂无数据用的 */
.analyse-container {
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  /* margin-top: 60px; */
  text-align: center;
  /* padding-top: 60px; */
}
.analyse-container-img {
  /* position: relative;
  top: 50%;
  transform: translateY(-50%); */
  padding: 30px 0;
}

.analyse-container-img p {
  font-size: 14px;
  color: #999999;
}
</style>