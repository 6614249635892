//平台台账
import request from '../request.js';
// 数据列表
export function getList(data) {
  return request({
    url: "/module-account/accountApp/queryPage",
    method: 'post',
    data
  })
}
// 编辑
export function appUpdate(data) {
  return request({
    url: "/module-account/accountApp/saveOrUpdate",
    method: 'post',
    data
  })
}

// 删除
export function appDel(id) {
  return request({
    url: "/module-account/accountApp/delete/"+id,
    method: 'delete'
  })
}